import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 800px) {
    & {
      width: 96%;
    }
  }
  @media (max-width: 430px) {
    & {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;
export const Divider = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: center;
`;
export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  @media (max-width: 730px) {
    & {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;
