import React from 'react';
import { Path, SVG } from './style';

export const PropriedadeSVG = () => {
  return (
    <SVG viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        
        d="M24.3,8.7c-2.9,0-5.2,2.3-5.2,5.2v4.2c0,0.5,0.4,1,1,1h8.5c0.5,0,1-0.4,1-1v-4.2C29.6,11,27.2,8.7,24.3,8.7z
	 M27.6,17.1h-6.5v-3.2c0-1.8,1.5-3.2,3.2-3.2c1.8,0,3.2,1.5,3.2,3.2V17.1z"
      />
      <Path
        
        d="M42,50c0.5,0,1-0.4,1-1V21.2l1.2,2.3c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1c0.5-0.3,0.7-0.9,0.4-1.3
	l-9.2-17c-0.1-0.2-0.3-0.4-0.5-0.4L24.7,0.1c-0.3-0.1-0.5-0.1-0.8,0L12.3,5.2c-0.2,0.1-0.4,0.2-0.5,0.4l-9.2,17
	c-0.3,0.5-0.1,1.1,0.4,1.3c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.9-0.5l1.2-2.3V49c0,0.5,0.4,1,1,1L42,50z M22.9,37.9l-10.1,8.9
	V29L22.9,37.9z M23.3,40.1V48h-8.9L23.3,40.1z M25.3,38.4L35.9,29v17.8l-4.7-4.2c-0.4-0.4-1-0.3-1.4,0.1c-0.4,0.4-0.3,1,0.1,1.4
	l4.4,3.9h-8.9L25.3,38.4L25.3,38.4z M25.3,35.7v-8.2h9.3L25.3,35.7z M23.3,35.6L14,27.5h9.3V35.6z M41.1,48h-3.1V26.5
	c0-0.5-0.4-1-1-1H11.8c-0.5,0-1,0.4-1,1V48H7.6V17.6l5.8-10.7l11-4.8l11,4.8l5.8,10.7V48z M32.3,43.7l-11.2-9.9l-1.1,1.7l13.5,12
	L32.3,43.7z"
      />
    </SVG>
  );
};
