import { toast } from 'react-toastify';
import './dist/ReactToastify.css'; // Não mexer, programador morreu

class Toast {
  error(message: string) {
    toast.error(message);
  }
  warning(message: string) {
    toast.warning(message);
  }
  success(message: string) {
    toast.success(message);
  }
  dark(message: string) {
    toast.dark(message);
  }
  info(message: string) {
    toast.info(message);
  }
}

export default new Toast();
