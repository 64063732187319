import React from 'react';
import { HomeSVG } from '../../assets/svg/Home';
import { AnimalSVG } from '../../assets/svg/Animal';
import { ProdutoSVG } from '../../assets/svg/Produto';
import { ReproducaoSVG } from '../../assets/svg/Reproducao';
import { PropriedadeSVG } from '../../assets/svg/Propriedade';
import { AgroSVG } from '../../assets/svg/Agro';
import { useState } from 'react';
import {
  DrawerMenu,
  Column,
  CoverLink,
  Collapse,
  StyledLink,
  Bar,
  DrawerButton,
  MenuIcon,
  Row,
  OpenButton,
  OpenIcon,
  BottomContainer,
  FullButton,
  Limit,
} from './style';
import RippleButton from '../RippleButton/index';
import { Animais, Estoques, Reprodução, Propriedades } from './sections';
import { useTheme } from '../../hooks/useTheme';
type DrawerProps = {
  DrawerDisplay: boolean;
};
function Drawer({ DrawerDisplay }: DrawerProps) {
  const [animalCollapse, setAnimalCollapse] = useState(false);
  const [storageCollapse, setStorageCollapse] = useState(false);
  const [reproductionCollapse, setReproductionCollapse] = useState(false);
  const [propertyCollapse, setPropertyCollapse] = useState(false);
  const [drawerIsActive, setDrawerIsActive] = useState(DrawerDisplay);
  const handleAnimalCollapse = () => setAnimalCollapse(!animalCollapse);
  const handleStorageCollapse = () => setStorageCollapse(!storageCollapse);
  const handleReproductionCollapse = () =>
    setReproductionCollapse(!reproductionCollapse);
  const handleDrawer = () => setDrawerIsActive(!drawerIsActive);
  const handlePropertyCollapse = () => setPropertyCollapse(!propertyCollapse);

  const { toggleTheme } = useTheme();
  return (
    <>
      <OpenButton onClick={() => handleDrawer()}>
        <OpenIcon src="/icons/menu.svg" />
      </OpenButton>
      <DrawerMenu active={drawerIsActive}>
        <Row>
          <AgroSVG />
          <DrawerButton onClick={() => handleDrawer()}>
            <MenuIcon src="/icons/cancel.svg" />
          </DrawerButton>
        </Row>
        <Limit>
          <CoverLink to="/painel">
            <RippleButton text="Página Inicial">
              <HomeSVG />
            </RippleButton>
          </CoverLink>
          <RippleButton text="Pecuária" buttonFunction={handleAnimalCollapse}>
            <AnimalSVG />
          </RippleButton>
          <Collapse active={animalCollapse}>
            <Column>
              {Animais.map((animal, index) => (
                <div key={index}>
                  <StyledLink
                    to={animal.redirect}
                    onClick={() => handleDrawer()}
                  >
                    {animal.text}
                  </StyledLink>
                </div>
              ))}
            </Column>
          </Collapse>
          <RippleButton
            text="Reprodução"
            buttonFunction={handleReproductionCollapse}
          >
            <ReproducaoSVG />
          </RippleButton>
          <Collapse active={reproductionCollapse} className="reproduction">
            <Column>
              {Reprodução.map((reprodução, index) => (
                <div key={index}>
                  <StyledLink
                    to={reprodução.redirect}
                    onClick={() => handleDrawer()}
                  >
                    {reprodução.text}
                  </StyledLink>
                  {reprodução.bar && <Bar />}
                </div>
              ))}
            </Column>
          </Collapse>
          <RippleButton
            text="Propriedade"
            buttonFunction={handleStorageCollapse}
          >
            <PropriedadeSVG />
          </RippleButton>
          <Collapse active={storageCollapse}>
            <Column>
              {Propriedades.map((propriedade, index) => (
                <div key={index}>
                  <StyledLink
                    to={propriedade.redirect}
                    onClick={() => handleDrawer()}
                  >
                    {propriedade.text}
                  </StyledLink>
                </div>
              ))}
            </Column>
          </Collapse>
          <RippleButton text="Produto" buttonFunction={handlePropertyCollapse}>
            <ProdutoSVG />
          </RippleButton>
          <Collapse active={propertyCollapse}>
            <Column>
              {Estoques.map((estoque, index) => (
                <div key={index}>
                  <StyledLink
                    to={estoque.redirect}
                    onClick={() => handleDrawer()}
                  >
                    {estoque.text}
                  </StyledLink>
                  {estoque.bar && <Bar />}
                </div>
              ))}
            </Column>
          </Collapse>
        </Limit>
        <BottomContainer>
          <FullButton
            text="Alterar Tema"
            type="button"
            onClick={() => toggleTheme()}
          />
        </BottomContainer>
      </DrawerMenu>
    </>
  );
}

export default Drawer;
