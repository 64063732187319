import styled from 'styled-components';

export const StyledTextArea = styled.textarea`
  border-radius: 3px;
  padding: 0.5rem;
  outline: 0;
  width: 41.5rem;
  resize: none;
  outline: none;
  border: 1px solid var(--outline);
  height: 10rem;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  background: var(--white-input);
  transition: box-shadow 0.3s;
  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px var(--selected);
  }

  @media (max-width: 525px) {
    width: 20rem;
    height: 20rem;
  }
  
`;
