import React, { createContext, useCallback } from 'react';
import { compareAsc } from 'date-fns';
import { decode } from 'jsonwebtoken';
import { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';

type sessionContextProps = {
  signOutByExpiredToken: () => void;
};

function getExpirationTime() {
  const token = localStorage.getItem('@AgroSimulador:token');
  if (!token) return;

  const decodedToken: any = decode(token);
  const expirationTime = new Date(decodedToken.exp * 1000); // data de expiração do token
  return expirationTime;
}
function compareDates(actualDate: Date, maxDate: Date) {
  const resultOfComparedDates = compareAsc(actualDate, maxDate);
  return resultOfComparedDates;
}
function verifyTokenExp(): boolean {
  const expirationTime = getExpirationTime();
  const actualTime = new Date();
  if (!expirationTime) return false;
  const result = compareDates(actualTime, expirationTime);
  if (result !== -1) return false;
  return true;
}

export const SessionContext = createContext({} as sessionContextProps);

export const SessionProvider: React.FC = ({ children }) => {
  const { user, signOut } = useAuth();

  const signOutByExpiredToken = useCallback(() => {
    const isTokenValid = verifyTokenExp();
    if (!isTokenValid) return signOut();
  }, [signOut]);

  useEffect(() => {
    if (user) {
      signOutByExpiredToken();
    }
  }, [signOutByExpiredToken, user]);

  return (
    <SessionContext.Provider value={{ signOutByExpiredToken }}>
      {children}
    </SessionContext.Provider>
  );
};
