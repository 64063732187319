import styled from 'styled-components';

type ItemPreviewProps = {
  index: number;
  columns: number;
};

type BackgroundPerIndex = {
  index: number;
  shadow?: boolean;
};

export const FlexContainer = styled.div<BackgroundPerIndex>`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props =>
    props.index % 2 === 0 ? 'var(--table-odd)' : 'var(--table-even)'};
  border-top: 2px solid var(--pastel-green);
  &:first-child {
    border-top: none;

    box-shadow: ${props =>
      props.shadow ? 'inset 0 3px 3px rgb(0 0 0 / 10%)' : ''};
  }
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  width: fit-content;
`;

export const HandleButton = styled.button`
  background-color: ${props => props.theme.colors.edit};
  outline: none;
  border: none;
  padding: 0;
  font-size: 0;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;

  img {
    width: 70%;
  }

  &:hover {
    filter: brightness(1.1);
  }
`;

export const ItemPreview = styled.div<ItemPreviewProps>`
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr) 9rem;
  background-color: transparent;
  div {
    padding: 0 0 0 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: 2px solid var(--table-line);
  }
  div:first-child {
    border-left: none;
    padding: 0 0 0 0;
  }
  > div:last-child {
    border-left: none;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  @media (max-width: 800px) {
    div {
      padding: 0;
      flex-shrink: 1;
      height: 4rem;
      overflow: hidden;
      h4 {
        max-height: 2.8rem;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    div:not(:first-child) {
      padding: 0 0 0 2rem;
    }
    div:last-child {
      padding: 0;
    }
  }
`;

export const InformationContainer = styled.div`
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  overflow: auto;
`;

export const EditButton = styled.button`
  background-color: ${props => props.theme.colors.edit};
  outline: none;
  border: none;
  padding: 0;
  font-size: 0;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;

  img {
    width: 70%;
  }

  &:hover {
    filter: brightness(1.1);
  }
`;

export const DeleteButton = styled(EditButton)`
  background-color: ${props => props.theme.colors.delete};
`;
export const Trinta = styled.div`
  width: 30px;
  height: 1px;
`;
