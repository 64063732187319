import styled, { DefaultTheme } from 'styled-components';

type DrawerProps = {
  active: boolean;
};
type GoBackProps = {
  selectedTheme: DefaultTheme;
};

export const NavBar = styled.header`
  background-color: var(--white);
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  padding: 1.5rem 1.2rem;
  border-bottom: 1px solid var(--gray-100);

  @media (max-width: 960px) {
    justify-content: space-between;
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 1rem;
  position: relative;
`;
export const MenuIcon = styled.img<DrawerProps>`
  width: 25px;
`;
export const Button = styled.button`
  font-size: 0;
  background: none;
  border: none;
  outline: none;
  transition: 0.3s;
`;

export const UserIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  object-fit: cover;
`;

export const Portrait = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoBack = styled.button<GoBackProps>`
  font-size: 0;
  outline: 0;
  border: none;
  transform: rotate(0deg);
  background-color: inherit;

  @media (max-width: 960px) {
    display: none;
  }
`;
export const Arrow = styled.div`
  width: 50px;
  height: 2rem;
  background-image: url(${props => props.theme.images.menuarrow});
  background-repeat: no-repeat;
  background-size: 30%;
  background-position-x: center;
  background-position-y: center;
`;

export const ReturnArrow = styled.button`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    position: absolute;
    z-index: 3;
    width: 3rem;
    height: 3rem;
    background: var(--white-bg);
    border-radius: 50%;
    border: 0;
    box-shadow: 0px 0px 6px -1px #00000066;
    left: 0.75rem;
    bottom: 0.75rem;
    background-image: url(${props => props.theme.images.menuarrow});
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center;
  }
`;