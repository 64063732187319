import React, { InputHTMLAttributes } from "react"
import { Input, RelativeForm } from './style'
type Option = {
  value: string | number;
  label: string;
};
interface IDataListProps extends InputHTMLAttributes<HTMLInputElement> {
  options: Array<Option>;
}
export function DataList({ options, value, ...rest }: IDataListProps) {

  return (
    <RelativeForm>
      <Input list="auto-complete" autoComplete="off" {...rest} id="list"/>
      <datalist id="auto-complete">
        <option value={''} label={'Selecione uma opção'} disabled />
        {options?.map(({ label }, index) => {
          return <option key={index} value={label} />;
        })}
      </datalist>
    </RelativeForm>
  );
}
