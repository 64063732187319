import React from 'react';
import { Container, Dot } from './style';
const Loading = () => {
  return (
    <Container>
      <img src="icons/logoold.svg" alt="logoloading" />
      <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'center' }}>
        <h3>Carregando</h3>
        <Dot delay="1s" />
        <Dot delay="1.1s" />
        <Dot delay="1.15s" />
      </div>
    </Container>
  );
};

export default Loading