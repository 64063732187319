import styled from 'styled-components';

export const Black = styled.path`
  fill: var(--gray-800);
`;

export const Green = styled.path`
  fill: #007836;
`;

export const SVG = styled.svg`
  width: 170px;
  height: 26px;
`;