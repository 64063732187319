import styled from 'styled-components';

export const RelativeForm = styled.div`
  position: relative;
`;

export const Input = styled.input`
  border-radius: 3px;
  padding: 0 1rem;
  outline: 0;
  border: 1px solid var(--outline);
  background: var(--white-input);
  width: 20rem;
  height: 2.5rem;
  align-items: center;
  padding-right: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-800);
  background-image: url('/icons/select.svg');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position-x: 98%;
  background-position-y: 50%;
  transition: box-shadow 0.3s;
  &::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--gray-500);
    display: flex;
    align-items: center;
  }
  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px var(--selected);
  }
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

export const DataContainer = styled.div`
  border-radius: 3px;
  outline: 0;
  position: absolute;
  border: 1px solid var(--outline);
  background: var(--white-input);
  width: 20rem;
  height: auto;
  max-height: 25rem;
  align-items: center;
  padding-right: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-300);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 0.2rem;
  overflow-y: scroll;
  gap: 0.8rem;
  &::placeholder {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: var(--gray-300);
    display: flex;
    align-items: center;
  }
  z-index: 2;

  option {
    width: 100%;
    padding: 0 1rem;
  }
  option:not(:disabled) {
    cursor: pointer;
    &:hover {
      background-color: var(--outline);
    }
  }
`;
