import React from 'react';
import { Path, SVG, Div } from './style';

export const ReproducaoSVG = () => {
  return (
    <Div>
      <SVG viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <Path
            
            d="M28.7,18.7c-2.7,0-4.8-2.2-4.8-4.8S26,9,28.7,9c2.7,0,4.8,2.2,4.8,4.8S31.4,18.7,28.7,18.7z M28.7,11.2
		c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7S30.2,11.2,28.7,11.2z"
          />
          <Path
            
            d="M26.4,32.5c-0.8,0-1.5-0.1-2.3-0.2c-0.6-0.1-0.9-0.7-0.8-1.2l0-0.2c0.1-0.5,0.6-0.8,1.2-0.7
		c0.6,0.1,1.3,0.2,1.9,0.2c7.2,0,12.8-6.5,11.5-13.9C37,11.8,33.2,8,28.6,7.2c-7.4-1.4-13.9,4.3-13.9,11.5c0,1,0.1,2,0.4,2.9
		c0.1,0.5-0.1,1-0.6,1.2l-0.2,0.1c-0.5,0.2-1.1-0.1-1.3-0.6c-0.4-1.4-0.5-2.8-0.5-4.2c0.3-7.1,6.2-12.9,13.3-13.2
		c7.9-0.3,14.4,6,14.4,13.8C40.2,26.3,34,32.5,26.4,32.5z"
          />
          <Path
            
            d="M26.2,36.9c-1.1,0-2.3-0.1-3.4-0.3c-0.6-0.1-1-0.7-0.8-1.3l0,0c0.1-0.5,0.7-0.9,1.2-0.8c1,0.2,2,0.3,3,0.3
		c9.3,0,16.7-7.7,16.3-17.1C42.1,9.4,35.3,2.6,27,2.2c-9.3-0.4-17.1,7-17.1,16.3c0,1.5,0.2,3,0.6,4.4c0.2,0.6-0.1,1.1-0.7,1.3l0,0
		c-0.6,0.2-1.2-0.1-1.4-0.7c-0.7-2.4-0.9-4.9-0.5-7.4C9,7.7,15.7,1.1,24.1,0.1c11.1-1.3,20.6,7.5,20.6,18.3
		C44.7,28.7,36.4,36.9,26.2,36.9z"
          />
          <Path
            
            d="M6.4,50c-0.5,0-1-0.4-1.1-0.9c-0.1-0.6,0.3-1.1,0.9-1.2c2.4-0.3,5.1-2.1,6.6-4.4c0.8-1.2,1.6-3,1-5.2
		c-1.6-6.1,1.3-8.5,1.5-8.6c0.5-0.4,1.2-0.3,1.5,0.2c0.4,0.5,0.3,1.1-0.2,1.5c-0.1,0.1-1.9,1.7-0.7,6.3c0.6,2.3,0.2,4.7-1.3,6.9
		c-1.8,2.8-5.1,4.9-8.1,5.3C6.5,50,6.5,50,6.4,50z"
          />
          <Path
            
            d="M17,31.5c-0.4,0-0.8-0.1-1.1-0.2c-0.9-0.4-1.5-1.3-1.7-2.5c-0.1-1,0-2.1,0.5-3.2c1.1-2.4,3.3-3.6,5.1-2.8l0,0
		c1.7,0.8,2.2,3.3,1.1,5.7c-0.5,1.1-1.2,1.9-2.1,2.5C18.3,31.3,17.6,31.5,17,31.5z M18.7,24.7c-0.5,0-1.4,0.6-2,1.8
		c-0.3,0.7-0.4,1.4-0.4,2c0.1,0.4,0.2,0.7,0.4,0.8c0.2,0.1,0.6,0,0.9-0.2c0.5-0.3,1-0.9,1.3-1.6c0.6-1.4,0.4-2.6-0.1-2.8
		C18.9,24.7,18.8,24.7,18.7,24.7z"
          />
        </g>
        <defs>
          <clipPath id="clip0"></clipPath>
        </defs>
      </SVG>
    </Div>
  );
};
