import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0px);
  }
`;

export const StyledButton = styled.button`
  background-color: ${props => props.theme.colors.greenlogo};
  color: var(--gray-50);
  outline: 0;
  border: 0;
  width: 15rem;
  height: 2.5rem;
  transition: all 0.3s;
  font-weight: 700;
  border-radius: 5px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  img {
    height: 16px;
  }
  h4 {
    color: var(--gray-50);
  }
  &:hover {
    filter: brightness(0.95);
    img {
      animation: ${animation} 0.8s infinite;
    }
  }
`;
