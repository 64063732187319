import { SelectHTMLAttributes } from 'react';
import { Select } from './style';
import React from 'react';

type Option = {
  value: string | number;
  label: string;
};
interface ISelectComponentProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<Option>;
  isOnError?: boolean;
}

export default function SelectComponent({
  options,
  isOnError,
  ...selectProps
}: ISelectComponentProps) {
  return (
    <Select isOnError={isOnError} {...selectProps}>
      <option value={''} disabled>
        Selecione uma Opção
      </option>
      {options?.map(({ value, label }, index) => {
        if (!value || !label) return;
        return (
          <option key={index} value={value}>
            {label}
          </option>
        );
      })}
    </Select>
  );
}
