import React from 'react';
import { Stroke, SVG } from './style';

export const ProdutoSVG = () => {
  return (
    <SVG viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Stroke
          d="M1 13.0714L25.7125 25.7125L51 13.0714L26.2875 1L1 13.0714Z"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Stroke
          d="M1 13.0714V38.3535L25.7125 51L51 38.9286V13.0714"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Stroke
          d="M25.7017 25.6962V50.9946"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Stroke
          d="M41.6467 8.5032L17.1566 21.3341L17.0915 30.8828L12.7512 27.4485H9.84326L9.96262 17.6558L33.9969 4.76514"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
    </SVG>
  );
};
