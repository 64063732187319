import styled from 'styled-components';

export const Container = styled.div`
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 770px) {
    & {
      padding: 0;
    }
  }
`;
