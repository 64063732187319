import React from 'react';
import { Wrapper, Redirect } from './style';

type Props = {
  content: string;
  link?: string;
};

export default function SelectDependent({
  content,
  link
}: Props) {
  return (
    <Wrapper>
      <div className="advise">
        <p>{content}{link && (<> <Redirect to={link}>aqui</Redirect>.</>)}</p>
        </div>
    </Wrapper>
  );
}
