export default {
  title: 'light',

  colors: {
    white: '#fff',
    whiteinput: '#fdfdfb',
    whitebg: '#fafafa',
    gray50: '#f7f8fa',
    gray100: '#e6e8eb',
    gray200: '#fafafa',
    gray500: '#808080',
    gray800: '#494d4b',
    green500: '#04d361',
    bgcolor: '#0b161e',
    greenlogo: '#49BC5A',
    success: '#118742',
    info: '#007d96',
    warning: '#c17100',
    danger: '#9b144c',
    green: '#49BC5A',
    red: '#BA6363',
    textcolor: '#787373',
    pastelgreen: '#96B79D',
    backgroundcolor: '#f0f4f7',
    outline: '#9DBBAC',
    tableeven: '#F6F6F4',
    tableodd: '#FDFDFB',
    tableinfo: '#EAF0EB',
    tableline: '#AAC8BA',
    usercolor: '#fafafa',
    edit: '#79C585',
    delete: '#96B79D',
    selected: '#9DBBAC66',
    ripplehover: '#e6e8eb',
  },

  images: {
    bg: '/icons/pecuaria/animal-background.svg',
    arrow: '/icons/arrows/styled-top-arrow.svg',
    menuarrow: '/icons/arrows/left-arrow.svg',
    menu: '/icons/drawer/back.svg',
    animal: '/icons/drawer/cow.svg',
    storage: '/icons/drawer/boxes.svg',
    money: '/icons/drawer/money.svg',
    select: '/icons/select.svg',
    error: '/icons/cover/error.svg',
    done: '/icons/cover/done.svg',
  },
};
