import React from 'react';
import { Path, Rect, SVG } from './style';

export const HomeSVG = () => {
  return (
    <SVG
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <Path d="M48.7501 21.65C48.7485 21.6488 48.7474 21.6473 48.7463 21.6462L28.3502 1.25084C27.4808 0.381089 26.3249 -0.0976562 25.0955 -0.0976562C23.8659 -0.0976562 22.7102 0.381089 21.8404 1.25084L1.45498 21.6359C1.44811 21.6428 1.44087 21.65 1.43438 21.6568C-0.350902 23.4524 -0.34785 26.3658 1.44316 28.1567C2.26141 28.9753 3.34173 29.4491 4.49721 29.4991C4.54451 29.5037 4.59181 29.506 4.6395 29.506H5.45202V44.5154C5.45202 47.4858 7.86903 49.9024 10.8395 49.9024H18.8192C19.6283 49.9024 20.2839 49.2463 20.2839 48.4376V36.67C20.2839 35.3147 21.3868 34.2121 22.7422 34.2121H27.4487C28.8041 34.2121 29.9066 35.3147 29.9066 36.67V48.4376C29.9066 49.2463 30.5623 49.9024 31.3714 49.9024H39.351C42.3219 49.9024 44.7385 47.4858 44.7385 44.5154V29.506H45.4923C46.7214 29.506 47.8773 29.0272 48.7474 28.1571C50.5403 26.3634 50.5411 23.4448 48.7501 21.65ZM46.6756 26.0857C46.3594 26.402 45.939 26.5763 45.4923 26.5763H43.2737C42.4646 26.5763 41.8088 27.232 41.8088 28.0411V44.5154C41.8088 45.8703 40.7064 46.9727 39.351 46.9727H32.8363V36.67C32.8363 33.6995 30.4197 31.2825 27.4487 31.2825H22.7422C19.7713 31.2825 17.3543 33.6995 17.3543 36.67V46.9727H10.8395C9.48455 46.9727 8.38172 45.8703 8.38172 44.5154V28.0411C8.38172 27.232 7.72598 26.5763 6.91688 26.5763H4.73639C4.7135 26.5748 4.691 26.5736 4.66773 26.5732C4.23132 26.5656 3.822 26.3924 3.5153 26.0853C2.86299 25.433 2.86299 24.3714 3.5153 23.7187C3.51568 23.7187 3.51568 23.7183 3.51606 23.7179L3.51721 23.7168L23.9125 3.32223C24.2284 3.00599 24.6484 2.83204 25.0955 2.83204C25.5422 2.83204 25.9621 3.00599 26.2784 3.32223L46.6692 23.7126C46.6722 23.7157 46.6756 23.7187 46.6787 23.7217C47.3276 24.3752 47.3264 25.4345 46.6756 26.0857Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <Rect width="50" height="50" />
        </clipPath>
      </defs>
    </SVG>
  );
};
