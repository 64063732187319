import styled from 'styled-components';

export const Box = styled.div`
  background-color: ${props => props.theme.colors.gray100};
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  position: relative;
  z-index: -2;
`;

export const DarkenBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
`;

export const CloseButton = styled.button`
  position: absolute;
  width: 1.5rem;
  font-size: 0;
  z-index: 1;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  outline: none;
  img {
    width: 1.5rem;
  }
`;
