import React from 'react';
import Aside from '../Aside';
import Content from '../Content';
import { Column, Container } from './style';
import MainHeader from '../MainHeader';
const Layout: React.FC = ({ children }) => {
  return (
    <Container>
      <Aside DrawerDisplay={false} />
      <Column>
        <MainHeader />
        <Content>{children}</Content>
      </Column>
    </Container>
  );
};

export default Layout;
