import React, { useState, createContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import light from '../../global/themes/light';
import dark from '../../global/themes/dark';

type themeContextProps = {
  getTheme: () => Themes;
  toggleTheme: () => void;
};
type Themes = typeof light | typeof dark;

export const themeContext = createContext({} as themeContextProps);
export const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Themes>(light);
  const getTheme = () => {
    return theme;
  };
  const toggleTheme = () => {
    if (theme === light) setTheme(dark);
    if (theme === dark) setTheme(light);
  };
  return (
    <themeContext.Provider value={{ getTheme, toggleTheme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </themeContext.Provider>
  );
};
