import React, { lazy, Suspense } from 'react';
import { Loading } from '../components';

export const UserRoutes = () => {
  const User = lazy(() => import('../pages/User/index'));

  return (
    <Suspense fallback={<Loading />}>
      <User />
    </Suspense>
  );
};
