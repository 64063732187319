import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
  --white: ${props => props.theme.colors.white};
  --white-input: ${props => props.theme.colors.whiteinput};
  --white-bg: ${props => props.theme.colors.whitebg};
  --gray-50: ${props => props.theme.colors.gray50};
  --gray-100: ${props => props.theme.colors.gray100};
  --gray-200: ${props => props.theme.colors.gray200};
  --gray-500: ${props => props.theme.colors.gray500};
  --gray-800: ${props => props.theme.colors.gray800};
  --green-500: ${props => props.theme.colors.green500};
  --bg-color: ${props => props.theme.colors.bgcolor};
  --green-logo: ${props => props.theme.colors.greenlogo};
  --success: ${props => props.theme.colors.success};
  --info: ${props => props.theme.colors.info};
  --warning: ${props => props.theme.colors.warning};
  --danger: ${props => props.theme.colors.danger};
  --green: ${props => props.theme.colors.green};
  --red: ${props => props.theme.colors.red};
  --text-color: ${props => props.theme.colors.textcolor};
  --pastel-green: ${props => props.theme.colors.pastelgreen};
  --background-color: ${props => props.theme.colors.backgroundcolor};
  --outline: ${props => props.theme.colors.outline};
  --table-even: ${props => props.theme.colors.tableeven};
  --table-odd: ${props => props.theme.colors.tableodd};
  --table-info: ${props => props.theme.colors.tableinfo};
  --table-line: ${props => props.theme.colors.tableline};
  --user-color: ${props => props.theme.colors.usercolor};
  --ripplehover: ${props => props.theme.colors.ripplehover};
  --selected: ${props => props.theme.colors.selected};
  overflow-x: hidden;
  }

  @media (min-width: 1280px) {
  html {
    font-size: 100%;
  }
  }
  @media (max-width: 1280px) {
  html {
    font-size: 80%;
  }
  }
  body {
    background: var(--background-color);
    color: var(--gray-100);
    -webkit-font-smoothing: antialiased;

  }
  body, input, button, span, label {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: var(--gray-800);

  }
  h1, h2, h3, h4, h5, h6, strong {
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  color: var(--gray-800);
  }
  button {
      cursor: pointer;
      outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
