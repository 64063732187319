import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
