import { Link } from 'react-router-dom';
import styled from 'styled-components';

type UserMenuProps = {
  active: boolean;
};

export const UserMenu = styled.div<UserMenuProps>`
  display: ${props => (props.active ? 'block' : 'none')};
  background-color: #0e1a24;
  position: fixed;
  top: 60px;
  right: 1.5rem;
  width: 20rem;
  height: fit-content;
  border-radius: 10px;
  z-index: 1;
`;
export const MiniIcon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
`;
export const UserContainer = styled.div`
  display: flex;
  padding: 0.5rem 0.5rem;
  align-items: flex-start;
  gap: 1rem;
  span {
    font-size: 0.875rem;
    color: var(--user-color);
    font-weight: 600;
  }
  h3 {
    font-size: 1.2rem;
    color: var(--user-color);
    font-weight: 600;
  }
  h5 {
    font-size: 1rem;
    color: var(--user-color);
    font-weight: 600;
  }
  border-bottom: 1px solid var(--user-color);
`;

export const Icon = styled.img`
  width: 20px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  gap: 1rem;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.2s;
  padding: 0.2rem 0.2rem;
  &:hover {
    filter: brightness(2);
  }
`;
export const ActionContainer = styled.div`
  display: flex;
  padding: 0.532rem 0.5rem;
  align-items: flex-start;
  gap: 1rem;
  h5 {
    font-size: 1rem;
    color: var(--user-color);
    font-weight: 600;
  }
  border-bottom: 1px solid var(--user-color);
  &:last-child {
    border-bottom: none;
  }
`;
export const ActionButton = styled.button`
  display: flex;
  width: 100%;
  gap: 1rem;
  text-decoration: none;
  background: none;
  border: none;
  border-radius: 5px;
  padding: 0.2rem 0.2rem;
  transition: 0.2s;

  &:hover {
    filter: brightness(2);
  }
`;

