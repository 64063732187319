import api from './api';
import { AxiosResponse } from 'axios';

type Methods = 'get' | 'post' | 'delete' | 'put' | 'patch';
interface IAxiosResponse extends AxiosResponse {
  error?: string;
  field?: string;
}

function errorHandling(response: IAxiosResponse) {
  if (response.field) throw new Error(response.field);
  if (response.error) throw new Error(response.error);
}

export async function requestBuilder(
  url: string,
  method: Methods,
  object?: any,
  params?: any,
): Promise<any[]> {
  try {
    const response: IAxiosResponse = await api({
      method: method,
      url: url,
      data: object ?? null,
      params: params ?? null,
    });
    const { data } = response;
    if (!data) errorHandling(response);
    return [data, null];
  } catch (error) {
    return [null, error.message];
  }
}
