import styled, { keyframes, css } from 'styled-components';

type ItemPreviewProps = {
  index: number;
  columns: number;
};
type IsItemActive = {
  isActive: boolean;
};
type BackgroundPerIndex = {
  index: number;
  shadow?: boolean;
};
type PaddingActive = {
  padding?: boolean;
};

const slideDown = keyframes`
  from {
    display: none;
    max-height: 0px;
    opacity: 0.7;
  }
  to {
    display: block;
    max-height: fit-content;
    opacity: 1;
  }
`;
const slideUp = keyframes`
  from {
    display: block;
    max-height: fit-content;
    opacity: 1;
  }
  to {
    display: none;
    max-height: 0px;
    opacity: 0.7;
  }
`;

export const FlexContainer = styled.div<BackgroundPerIndex>`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0 1rem;
  align-items: center;
  justify-content: flex-start;
  background-color: ${props =>
    props.index % 2 === 0 ? 'var(--table-odd)' : 'var(--table-even)'};
  border-top: 2px solid var(--pastel-green);
  &:first-child {
    border-top: none;

    box-shadow: ${props =>
      props.shadow ? 'inset 0 3px 3px rgb(0 0 0 / 10%)' : ''};
  }
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  width: fit-content;
`;

export const ExpandButton = styled.button<IsItemActive>`
  background: none;
  outline: none;
  border: none;
  font-size: 0;
  padding: 0;
  transform: ${props => (props.isActive ? 'rotate(270deg)' : 'rotate(180deg)')};
  transition: transform 0.45s;
  img {
    width: 30px;
  }
`;
export const EditButton = styled.button`
  background-color: ${props => props.theme.colors.edit};
  outline: none;
  border: none;
  padding: 0;
  font-size: 0;
  width: 2.4rem;
  height: 2.4rem;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;

  img {
    width: 70%;
  }

  &:hover {
    filter: brightness(1.1);
  }
`;

export const DeleteButton = styled(EditButton)`
  background-color: ${props => props.theme.colors.delete};
`;

export const ItemPreview = styled.div<ItemPreviewProps>`
  width: 100%;
  height: 4rem;
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr) 9rem;
  background-color: ${props =>
    props.index % 2 === 0 ? 'transparent' : 'var(--table-even)'};
  div {
    padding: 0 0 0 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: 2px solid var(--table-line);
  }
  div:first-child {
    border-left: none;
  }
  > div:last-child {
    border-left: none;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }
  @media (max-width: 800px) {
    div {
      padding: 0;
      flex-shrink: 1;
      height: 4rem;
      overflow: hidden;
      h4 {
        max-height: 2.8rem;
        flex-shrink: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const Expanded = styled.div<IsItemActive>`
  overflow: hidden;
  border: none;
  width: 100%;
  background-color: var(--table-info);
  box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.1);
  animation: ${props =>
      props.isActive
        ? css`
            ${slideDown}
          `
        : css`
            ${slideUp}
          `}
    600ms forwards;
`;

export const InformationContainer = styled.div<PaddingActive>`
  padding: ${props => (props.padding === false ? '0' : '1rem')};
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  h3 {
    font-size: 1rem;
  }
`;

export const Arrow = styled.div`
  width: 30px;
  height: 4rem;
  background-image: url(${props => props.theme.images.menuarrow});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position-x: center;
  background-position-y: center;
`;
