import React from 'react';
import { Header, Flex } from './style';
import { Button } from '../index';
import { useHistory } from 'react-router';

type HeaderProps = {
  type: string;
  url: string;
  children: JSX.Element;
  add?: boolean;
};

const ListHeader = (props: HeaderProps) => {
  const history = useHistory();

  const redirectToCreate = () => {
    history.push(`/painel/${props.url}`);
  };
  return (
    <Header>
      <h3>Pesquisar {props.type}</h3>
      <Flex>
        <div>{props.children}</div>

        {props.add !== false && (
                  <Button
                  text={`Cadastrar ${props.type}`}
                  type="button"
                  onClick={() => redirectToCreate()}
                />
                )}

        
      </Flex>
    </Header>
  );
};

export default ListHeader;
