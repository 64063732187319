import React from 'react';
import GlobalStyleProvider from './global/globalStyle';
import { AuthProvider } from './provider/Contexts/UserContexts/authContext';
import { ThemeProvider } from './provider/Contexts/themeContext';
import { SessionProvider } from './provider/Contexts/UserContexts/sessionContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';

function App() {
  return (
    <>
      <ThemeProvider>
        <GlobalStyleProvider />
        <AuthProvider>
          <SessionProvider>
            <Router>
              <Routes />
            </Router>
          </SessionProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
