import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import api from '../../../services/api/api';
import { requestBuilder } from '../../../services/api/requestBuilder';
import Toast from '../../../utils/Notify/Toast';
import { decode, JwtPayload } from 'jsonwebtoken';

type Base = {
  nome_base: string;
};
interface Decoded extends JwtPayload {
  base: string;
}
interface IBaseContextProps {
  bases: Base[] | undefined;
  areBasesLoaded: boolean;
  actualBase: string | undefined;
  createBase: (nome: string) => Promise<void>;
  linkUserToBase: (nomeBase: string) => Promise<void>;
  connectToBase: (nomeBase: string) => Promise<boolean | undefined>;
  tokenHaveBase: () => string | null | undefined;
}

export const BaseContext = createContext({} as IBaseContextProps);

export const BaseProvider: React.FC = ({ children }) => {
  const [bases, setBases] = useState<Base[] | undefined>();
  const [actualBase, setActualBase] = useState('');
  const [areBasesLoaded, setAreBasesLoaded] = useState(false);
  const { user } = useAuth();
  const { id, tipo } = user;

  async function fetchBases() {
    const [data, error] = await requestBuilder(
      'usuario-base/listar',
      'get',
      undefined,
      {
        filtros_usuarios_bases: {
          id_usuario: [id],
        },
      },
    );
    if (error) return setAreBasesLoaded(true);
    setBases(data?.object);
    setAreBasesLoaded(true);
  }

  async function linkUserToBase(nomeBase: string) {
    await requestBuilder('usuarios/vincular-usuario-base', 'put', {
      id_usuario: id,
      nome_base: nomeBase,
      tipo_usuario: tipo,
    });
  }
  async function createBase(nome: string) {
    await requestBuilder('base', 'post', {
      nome,
    });
  }
  async function connectToBase(nomeBase: string) {
    try {
      const [data, error] = await requestBuilder(
        'sessions/conectar-base',
        'post',
        {
          nome_base: nomeBase,
        },
      );
      if (error) return error;
      if (!data) return error;
      setActualBase(nomeBase);
      api.defaults.headers.authorization = `Bearer ${data?.token}`;
      localStorage.setItem('@AgroSimulador:token', data?.token);
      Toast.success('Conectado à base com sucesso!');
    } catch (e) {
      console.log(e);
    }
  }
  function tokenHaveBase() {
    const token = localStorage.getItem('@AgroSimulador:token');
    if (!token) return;
    const decodedToken = decode(token);
    const { base }: any = decodedToken;
    if (!base) return;
    return base;
  }

  useEffect(() => {
    fetchBases();
    // eslint-disable-next-line
  }, []);

  return (
    <BaseContext.Provider
      value={{
        bases,
        createBase,
        linkUserToBase,
        connectToBase,
        actualBase,
        tokenHaveBase,
        areBasesLoaded,
      }}
    >
      {children}
    </BaseContext.Provider>
  );
};
