export const Animais = [
  {
    redirect: '/painel/animal/listar-animal',
    text: 'Listagem de Animais',
  },
  {
    redirect: '/painel/animal/registrar-animal',
    text: 'Cadastro de Animais',
    bar: true,
  },
];

export const Reprodução = [
  {
    redirect: '/painel/cio/listar-cio',
    text: 'Listagem de Cio',
  },
  {
    redirect: '/painel/cio/registrar-cio',
    text: 'Cadastro de Cio',
    bar: true,
  },
  {
    redirect: '/painel/cobertura/listar-cobertura',
    text: 'Listagem de Inseminação',
  },
  {
    redirect: '/painel/cobertura/registrar-cobertura',
    text: 'Cadastro de Inseminação',
    bar: true,
  },
  {
    redirect: '/painel/prenhez/listar-prenhez',
    text: 'Listagem de Prenhez',
    bar: true,
  },
  {
    redirect: '/painel/parto/listar-parto',
    text: 'Listagem de Parto',
  },
  {
    redirect: '/painel/parto/registrar-parto',
    text: 'Cadastro de Parto',
  }
];

export const Estoques = [
  {
    redirect: '/painel/produtos/listar-produtos',
    text: 'Listagem de Produtos',
  },
  {
    redirect: '/painel/produtos/registrar-produto',
    text: 'Cadastro de Produto',
    bar: true,
  },
  {
    redirect: '/painel/grupos/listar-grupos',
    text: 'Listagem de Grupo',
  },
  {
    redirect: '/painel/grupos/registrar-grupo',
    text: 'Cadastro de Grupo',
    bar: true,
  },
  {
    redirect: '/painel/marcas/listar-marcas',
    text: 'Listagem de Marca',
  },
  {
    redirect: '/painel/marcas/registrar-marca',
    text: 'Cadastro de Marca',
  },
];

export const Propriedades = [
  {
    redirect: '/painel/listar-propriedade',
    text: 'Listagem de Propriedade',
  },
  {
    redirect: '/painel/registrar-propriedade',
    text: 'Cadastro de Propriedade',
  },
];
