import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { AppRoutes } from './App.routes';
import { UserRoutes } from './User.routes';
import { BaseProvider } from '../provider/Contexts/UserContexts/baseContext';
import { ModalProvider } from '../provider/Contexts/baseModalContext';
import { ToastContainer } from 'react-toastify';

export const Routes = () => {
  const { user } = useAuth();
  const isLoggedIn = !!user;
  if (isLoggedIn) {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={2}
        />
        <BaseProvider>
          <ModalProvider>
            <AppRoutes />
          </ModalProvider>
        </BaseProvider>
      </>
    );
  }
  return <UserRoutes />;
};
