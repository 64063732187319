import styled from 'styled-components';

export const Background = styled.div`
  background-image: url(${props => props.theme.images.bg});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  z-index: -2;
  height: 50vh;
  width: 100vw;
  background-position-x: center;
  background-position-y: 15vh;
  position: fixed;
`;
