import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Loading, Layout } from '../components/index';
import { PrivateRoute } from './PrivateRoutes';
import { useSession } from '../hooks/useSession';
import { useBase } from '../hooks/useBase';


export const AppRoutes = () => {
  //hooks
  const { signOutByExpiredToken } = useSession();
  const history = useHistory();
  const { bases, areBasesLoaded, tokenHaveBase } = useBase();
  const location = useLocation();

  //imports
  const Dashboard = lazy(() => import('../pages/App/Dashboard'));
  const Account = lazy(() => import('../pages/Account/index'));
  const LiveStock = lazy(() => import('../pages/App/Livestock'));
  const Address = lazy(() => import('../pages/App/Address/index'));
  const Products = lazy(() => import('../pages/App/Products/index'));
  const Bases = lazy(() => import('../pages/Base/index'));
  const ErrorPage = lazy(() => import('../pages/ErrorPage'));
  //listener

  const shouldRenderLayout = location?.pathname.split('/')[1] === 'painel';
  const shouldRedirectToSelect = bases && bases.length >= 1 && !tokenHaveBase();
  const shouldRedirectToCreate = areBasesLoaded && !bases;
  const shouldRedirectToPainel = !shouldRedirectToSelect && !shouldRedirectToCreate;
  history.listen(() => signOutByExpiredToken);
  return (
    <Switch>
      <Suspense fallback={<Loading />}>
        {shouldRedirectToSelect && <Redirect from="*" to="/bases/selecionar" />}
        {shouldRedirectToCreate && <Redirect from="*" to="/bases/criar" />}
        {shouldRedirectToPainel && <Redirect from="*" to="/painel" /> }
        <PrivateRoute path={`/bases`} component={() => <Bases />} />
        <PrivateRoute path={`/error`} component={() => <ErrorPage />} />
        {shouldRenderLayout && (
          <Layout>
            <PrivateRoute
              exact
              path="/painel"
              component={() => <Dashboard />}
            />
            <PrivateRoute path="/painel" component={() => <Account />} />
            <PrivateRoute path="/painel" component={() => <LiveStock />} />
            <PrivateRoute path="/painel" component={() => <Address />} />
            <PrivateRoute path="/painel" component={() => <Products />} />
          </Layout>
        )}
      </Suspense>
    </Switch>
  );
};
