export default {
  title: 'dark',

  colors: {
    white: '#222831',
    whiteinput: '#292F32',
    whitebg: '#393E46',
    gray50: '#f7f8fa',
    gray100: '#222729',
    gray200: '#E8E8E8',
    gray500: '#808080',
    gray800: '#E8E8E8',
    green500: '#04d361',
    bgcolor: '#0b161e',
    greenlogo: '#045757',
    success: '#118742',
    info: '#007d96',
    warning: '#c17100',
    danger: '#9b144c',
    green: '#49BC5A',
    red: '#BA6363',
    textcolor: '#E8E8E8',
    pastelgreen: '#045757',
    backgroundcolor: '#393E46',
    outline: '#045757',
    tableeven: '#222729',
    tableodd: '#292F32',
    tableinfo: '#21292A',
    tableline: '#044343',
    usercolor: '#E8E8E8',
    edit: '#045757',
    delete: '#044343',
    selected: '#04575766',
    ripplehover: '#1D2228',
  },
  images: {
    bg: '/icons/pecuaria/dark-animal-background.svg',
    arrow: '/icons/arrows/dark-styled-top-arrow.svg',
    menuarrow: '/icons/arrows/dark-left-arrow.svg',
    menu: '/icons/drawer/dark-back.svg',
    animal: '/icons/drawer/dark-cow.svg',
    storage: '/icons/drawer/dark-boxes.svg',
    money: '/icons/drawer/dark-money.svg',
    select: '/icons/select-dark.svg',
    error: '/icons/cover/dark-error.svg',
    done: '/icons/cover/dark-done.svg',
  },
};
