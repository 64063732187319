import styled from 'styled-components';

export const Path = styled.path`
  fill: var(--gray-800);
`;

export const Stroke = styled.path`
  stroke: var(--gray-800);
  stroke-width: 3px;
`;

export const Rect = styled.rect`
  fill: var(--gray-800);
`;

export const SVG = styled.svg`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Div = styled.div`
  width: 1rem;
  height: 1rem;
`;