import React, { FormEvent, useState, ChangeEvent } from 'react';
import { useBase } from '../../../hooks/useBase';
import {
  FormCard,
  Title,
  InputContainer,
  ImagesContainer,
} from './style';
import {AgroSVG} from '../../../assets/svg/Agro'
import { Select, Button } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import Toast from '../../../utils/Notify/Toast';

type Option = {
  value: string | number;
  label: string;
};
type OnChangeEvent = ChangeEvent<HTMLSelectElement>;

export function ListBasesForm() {
  const { bases, connectToBase } = useBase();
  const baseOptions: Option[] = [];
  const [selectedBaseForm, setSelectedBaseForm] = useState('');
  const history = useHistory();
  const { closeModal } = useModal();

  const handleSelectValue = (event: OnChangeEvent) => {
    setSelectedBaseForm(event.target.value);
  };
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!selectedBaseForm) return Toast.info('Uma base deve ser selecionada!');
    const resp = await connectToBase(selectedBaseForm);
    if(resp) return
    history.push('/painel');
    closeModal();
  };

  bases?.map(({ nome_base }) => {
    return baseOptions.push({ value: nome_base, label: nome_base });
  });
  return (
      <FormCard onSubmit={handleSubmit}>
        <Title>Selecione sua Empresa:</Title>
        <ImagesContainer>
          <img src="/icons/Group1.svg" alt="" />
          <AgroSVG/>
        </ImagesContainer>
        <InputContainer>
          <h4>Empresas Vinculadas:</h4>
          <Select
            options={baseOptions}
            onChange={handleSelectValue}
            value={selectedBaseForm}
          />
        </InputContainer>
        <Button text="Selecionar empresa" type="submit" />
      </FormCard>
  );
}
