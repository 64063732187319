import React from 'react';
import { ReactNode } from 'react';
import {
  FlexContainer,
  ItemPreview,
  Flex,
  EditButton,
  DeleteButton,
  Trinta,
} from './style';

type ItemCollapseProps = {
  children: ReactNode;
  columns: number;
  index: number;
  handleEditItem?: () => Promise<void> | unknown;
  handleDeleteItem?: () => Promise<void> | unknown;
  shadow?: boolean;
  edit?: boolean;
  delete?: boolean;
};

function NonCollapse(props: ItemCollapseProps) {
  return (
    <>
      <FlexContainer index={props.index} shadow={props.shadow}>
        <Trinta />
        <ItemPreview columns={props.columns} index={props.index}>
          {props.children}
          <Flex>
            {props.edit !== false && (
              <EditButton onClick={props.handleEditItem}>
                <img src="/icons/crud/edit.svg" alt="edit-icon" />
              </EditButton>
            )}
            {props.delete !== false && (
              <DeleteButton onClick={props.handleDeleteItem}>
              <img src="/icons/crud/delete.svg" alt="delete-icon" />
            </DeleteButton>
            )}
          </Flex>
        </ItemPreview>
      </FlexContainer>
    </>
  );
}

export default NonCollapse;
