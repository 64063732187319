import styled, { keyframes } from 'styled-components';

type RippleProps = {
  y?: number;
  x?: number;
};

const ANIMATION_MS = 400;
const CIRCLE_SIZE = 50;
const ripple = keyframes`
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    transform: scale(8);
  }
  100% {
    opacity: 0;
  }
`;
export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
  z-index: 3;
  &:hover {
    background-color: var(--ripplehover);
  }
`;
export const Flex = styled.div`
  display: flex;
  flex: 1;
  padding: 0 1rem;
  align-items: center;
  justify-content: left;
  gap: 0.5rem;

  h3{
    white-space: nowrap;
  }
`;
export const Button = styled.button`
  position: relative;
  display: inline-block;
  height: 44px;
  width: 100%;
  font-size: 12px;
  color: var(--gray-800);
  background: inherit;
  border: 0;
  outline: 0;
  cursor: pointer;
  overflow: hidden;
  letter-spacing: 1px;
`;
export const ButtonRipple = styled.div<RippleProps>`
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  height: ${CIRCLE_SIZE}px;
  width: ${CIRCLE_SIZE}px;
  background: currentColor;
  border-radius: 50%;
  opacity: 0.3;
  animation-name: ${ripple};
  animation-duration: ${ANIMATION_MS * 3}ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  pointer-events: none;
`;

