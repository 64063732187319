import styled from 'styled-components';

export const Path = styled.path`
  fill: var(--gray-800);
`;

export const Rect = styled.rect`
  fill: var(--gray-800);
`;

export const SVG = styled.svg`
  width: 1rem;
  height: 1rem;
`;