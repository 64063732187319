import React from 'react';
import { useState } from 'react';
import { ReactNode } from 'react';
import {
  FlexContainer,
  ItemPreview,
  ExpandButton,
  Flex,
  EditButton,
  DeleteButton,
  Expanded,
  InformationContainer,
  Arrow,
} from './style';

type ItemCollapseProps = {
  children: ReactNode[];
  columns: number;
  index: number;
  handleEditItem?: () => Promise<void> | unknown;
  handleDeleteItem?: () => Promise<void> | unknown;
  handleOpen?: () => Promise<void> | unknown;
  block?: boolean;
  add?: boolean;
  buttons?: boolean;
  shadow?: boolean;
  edit?: boolean;
  delete?: boolean;
  padding?: boolean;
};
function ItemCollapse(props: ItemCollapseProps) {
  const [displayItem, setDisplayItem] = useState(false);
  return (
    <>
      <FlexContainer index={props.index} shadow={props.shadow}>
        <ExpandButton
          isActive={displayItem}
          onClick={() => {
            setDisplayItem(!displayItem);
            if (props.handleOpen) {
              if (!displayItem) props.handleOpen();
            }
          }}
        >
          <Arrow />
        </ExpandButton>
        <ItemPreview columns={props.columns} index={props.index}>
          {props.children[0]}
          <Flex>
            {props.buttons !== false && (
              <>
                {props.edit !== false && (
                  <EditButton onClick={props.handleEditItem}>
                    <img
                      src={
                        props.add
                          ? '/icons/crud/add.svg'
                          : '/icons/crud/edit.svg'
                      }
                      alt={props.add ? 'add-user' : 'edit-icon'}
                    />
                  </EditButton>
                )}

                {props.delete !== false && (
                  <DeleteButton onClick={props.handleDeleteItem}>
                    <img
                      src={
                        props.block
                          ? '/icons/crud/block.svg'
                          : '/icons/crud/delete.svg'
                      }
                      alt={props.block ? 'block-base' : 'delete-icon'}
                    />
                  </DeleteButton>
                )}
              </>
            )}
          </Flex>
        </ItemPreview>
      </FlexContainer>
      <Expanded isActive={displayItem}>
        <InformationContainer padding={props.padding}>
          {props.children[1]}
        </InformationContainer>
      </Expanded>
    </>
  );
}
export default ItemCollapse;
