import styled, { keyframes } from 'styled-components';

type DotsProps = {
  delay: string;
};

const dotsAnimation = keyframes`
    0% {
      opacity: 0;
    }
    75% {
    opacity: 1;
    }
    100% {
    opacity: 0;
    }
  `;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  img {
    width: 30rem;
    margin-bottom: 5rem;
  }
`;

export const Dot = styled.div<DotsProps>`
  width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 100%;
  animation: ${dotsAnimation} ${props => props.delay} infinite;
`;
