import React, { Ref } from 'react';
import { ListBasesForm } from '../../Forms/Base/ListBase';
import { useAuth } from '../../hooks/useAuth';
import { useBase } from '../../hooks/useBase';
import { useModal } from '../../hooks/useModal';
import Modal from '../Modal';
import {
  Icon,
  MiniIcon,
  StyledLink,
  UserContainer,
  UserMenu,
  ActionButton,
  ActionContainer,
} from './style';

interface IUserCardProps {
  profileIsActive: boolean;
  CardRef: Ref<HTMLDivElement>;
  signOutRef: Ref<HTMLButtonElement>;
}

export const UserCardMenu = ({
  CardRef,
  profileIsActive,
  signOutRef,
}: IUserCardProps) => {
  const { signOut, user } = useAuth();
  const { bases } = useBase();
  const { openModal } = useModal();
  return (
    <>
      <UserMenu active={profileIsActive} ref={CardRef}>
        <UserContainer>
          <MiniIcon
            src={user?.avatar ? user?.avatar : '/icons/user.svg'}
            alt=""
          />
          <div>
            <h3>{user?.nome}</h3>
            <span>{user?.email}</span>
          </div>
        </UserContainer>
        <UserContainer>
          <StyledLink to="/painel/perfil">
            <Icon src="/icons/setting.svg" />
            <h5>Configurações da Conta</h5>
          </StyledLink>
        </UserContainer>
        {(user?.tipo === 'administrador' || user?.tipo === 'desenvolvedor') && (
          <UserContainer>
            <StyledLink to="/painel/painel-admin">
              <Icon src="/icons/Admin.svg" />
              <h5>Painel de Administrador</h5>
            </StyledLink>
          </UserContainer>
        )}
        {bases && bases?.length > 1 && (
          <ActionContainer>
            <ActionButton
              onClick={() => {
                openModal();
              }}
            >
              <Icon src="/icons/change.svg" />
              <h5>Alterar Empresa</h5>
            </ActionButton>
          </ActionContainer>
        )}
        <ActionContainer>
          <ActionButton
            ref={signOutRef}
            onClick={() => {
              signOut();
            }}
          >
            <Icon src="/icons/logout.svg" />
            <h5>Sair da Conta</h5>
          </ActionButton>
        </ActionContainer>
      </UserMenu>
      <Modal>
        <ListBasesForm />
      </Modal>
    </>
  );
};
