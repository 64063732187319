import { useContext } from 'react';
import { BaseContext } from '../provider/Contexts/UserContexts/baseContext';

export function useBase() {
  const context = useContext(BaseContext);
  if (!context) {
    throw new Error('useBase must be used within an AuthProvider');
  }
  return context;
}
