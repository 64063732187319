import React, { MouseEventHandler } from 'react';
import { StyledButton } from './style';

type ButtonProps = {
  type: 'button' | 'submit';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
};

const Button = (props: ButtonProps) => {
  return (
    <StyledButton {...props}>
      <h4>{props.text}</h4>
    </StyledButton>
  );
};

export default Button;
