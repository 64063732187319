import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Redirect = styled(Link)`
  color: var(--green-logo);
  text-decoration: underline;
  font-size: 0.8125rem;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 2.5rem;
  position: absolute;
  bottom: 0;
  border-radius: 3px;
  border: 1px solid var(--danger);
  transition: box-shadow 0.3s;
  &::after {
    position: absolute;
    content: '';
    top: -0.35rem;
    right: -1rem;
    height: 3rem;
    width: 3rem;
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px #9b144c66;
    .advise > p {
      color: var(--gray-50);
      font-size: 0.8125rem;
      white-space: normal;
      line-break: auto;
      width: 90%;
    }

    .advise {
      top: -0.35rem;
      right: -55%;
      z-index: 3;
      position: absolute;
      align-items: center;
      display: flex;
      height: 3rem;
      justify-content: center;
      width: fit-content;
      max-width: 10.31rem;
      border-radius: 3px;
      background-color: #1c1e21;
    }

    .advise::after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      border-top: 12px solid #1c1e21;
      border-radius: 4px;
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
      transform: rotate(90deg);
    }
    @media (min-width: 800px) {
      .advise::after {
        left: -0.9rem;
      }
    }
    @media (max-width: 800px) {
      .advise::after {
        transform: rotate(270deg);
        right: -1.2rem;
      }
      .advise {
        right: 12%;
      }
    }
  }

  .advise {
    display: none;
  }

  background-image: url('/icons/error.svg');
  background-repeat: no-repeat;
  background-size: 1.3rem;
  background-position-x: 98%;
  background-position-y: 50%;
`;
