import { useContext } from 'react';
import { SessionContext } from '../provider/Contexts/UserContexts/sessionContext';

export function useSession() {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within an AuthProvider');
  }
  return context;
}
