import React from 'react';
import { TextareaHTMLAttributes } from 'react';
import { StyledTextArea } from './style';


type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement>


export function TextArea(props: TextAreaProps) {
  return <StyledTextArea {...props} />;
}

export default TextArea