import React, { ReactNode } from 'react';
import { Container } from './style';

type ListContainerProps = {
  children: ReactNode;
};

 function ListContainer({ children }: ListContainerProps) {
  return <Container>{children}</Container>;
}
export default ListContainer