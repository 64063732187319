import React, { createContext, useState } from 'react';

type modalContextProps = {
  openModal: () => void;
  closeModal: () => void;
  modalStatus: boolean;
};

export const modalContext = createContext({} as modalContextProps);
export const ModalProvider: React.FC = ({ children }) => {
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const openModal = () => setModalStatus(true);
  const closeModal = () => setModalStatus(false);
  return (
    <modalContext.Provider value={{ openModal, closeModal, modalStatus }}>
      {children}
    </modalContext.Provider>
  );
};
