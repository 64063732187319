import axios from 'axios';
import Toast from '../../utils/Notify/Toast';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.response.use(
  response => {
    if (response.status === 401) {
      Toast.error('É necessário autenticar-se novamente');
    }
    if (response.config.method === 'post') Toast.success(response.data.message);
    if (response.config.method === 'delete')
      Toast.success(response.data.message);
    if (response.config.method === 'put') Toast.success(response.data.message);

    return response;
  },
  error => {
    if (error.response && error.response.data) {
      if (error?.response?.status === 404)
        return Toast.error(
          'Parece que ouve um erro ao conectar-se com o servidor',
        );
      if (error?.response?.status === 400) {
        if (
          error.response.data.message === 'celebrate request validation failed'
        )
          return;
        return Toast.warning(error.response.data.message);
      }
      if (error?.response?.status === 500)
        return Toast.error('Erro com o servidor');
      if (error?.response?.status === 401)
        return Toast.error('Credênciais inválidas');
      return error.response.data;
    }
    Toast.error(
      'Parece que houve um erro com o servidor, por favor, tente novamente mais tarde!',
    );
    return error;
  },
);
export default api;
