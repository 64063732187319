import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '../index';
const pushFromLeft = keyframes`
  from {
    left: -20rem;
  }
  to {
    left: 0rem;
  }

`;
const pushToLeft = keyframes`
  from {
    left: 0rem;
  }
  to {
    left: -20rem;
  }
`;
const slideDown = keyframes`
  from {
    visibility: hidden;
    max-height: 0px;
    opacity: 0;
  }
  to {
    visibility: auto;
    max-height: 205px;
    opacity: 1;
  }
`;
const slideUp = keyframes`
  from {
    visibility: auto;
    max-height: 180px;
    opacity: 1;
  }
  to {
    visibility: hidden;
    max-height: 0px;
    opacity: 0;
  }
`;
type DrawerProps = {
  active: boolean;
};

type CollapseProps = {
  active: boolean;
};
export const DrawerMenu = styled.aside<DrawerProps>`
  background-color: var(--white);
  color: white;
  height: 100vh;
  min-width: 16rem;
  padding: 24px 0;
  visibility: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  //transition: 500ms;
  position: relative;
  border-right: 1px solid var(--gray-100);

  h4 {
    white-space: nowrap;
  }
  h5 {
    white-space: nowrap;
    font-weight: 400;
    font-size: 1rem;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 5;
    width: 20rem;
    overflow: hidden;
    animation: ${props =>
      props.active
        ? css`
            ${ pushToLeft} 0 forwards;
          `
        : css`
            ${pushFromLeft} 0 forwards;
          `};
  }
`;

export const Collapse = styled.div<CollapseProps>`
  animation: ${props =>
      props.active
        ? css`
            ${slideDown}
          `
        : css`
            ${slideUp}
          `}
    0ms forwards;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 0.3rem;
`;
export const Bar = styled.hr`
  width: 90%;
  height: 1px;
  margin-top: 0.4rem;
  margin-bottom: 0.1rem;
  background-color: var(--gray-800);
  border: 0;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 0.3rem;
  justify-content: center;
  padding-left: 1rem;
  div {
    width: 100%;
  }
`;
export const CoverLink = styled(Link)`
  margin-top: 19px;
  outline: none;
  text-decoration: none;
  width: 100%;
  height: auto;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s;
  color: var(--gray-800);
  white-space: nowrap;

  &:visited {
    color: var(--gray-800);
  }
  &:hover {
    filter: brightness(1.3);
    text-decoration: underline;
  }
`;

export const Logo = styled.img`
  width: 170px;
`;

export const DrawerButton = styled.button`
  font-size: 0;
  background: none;
  border: none;
  outline: none;
  transition: 0.3s;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const FullButton = styled(Button)`
  width: 100%;
  border-radius: 0;
`;

export const MenuIcon = styled.img`
  width: 1.6rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

export const OpenButton = styled.button`
  font-size: 0;
  background: none;
  border: none;
  outline: none;
  transition: 0.3s;
  position: absolute;
  z-index: 0;
  top: 1.85rem;
  left: 1.2rem;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

export const OpenIcon = styled.img`
  width: 2rem;
`;

export const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Limit = styled.div`
  width: 100%;
  overflow-y: hidden;
  max-height: calc(100% - 36px - 2.6rem);
  height: min-content;

  @media (max-height: 580px) {
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: var(--white);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--edit);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--delete);
  }
`;
