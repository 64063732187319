import styled from 'styled-components';

type InputStyleType = {
  isOnError?: boolean;
};
export const Select = styled.select<InputStyleType>`
  border-radius: 3px;
  padding: 0 1rem;
  padding-right: 0;
  outline: 0;
  border: ${({ isOnError }) =>
    isOnError ? '1px solid red' : '1px solid var(--outline)'};
  background: var(--white-input);
  width: 20rem;
  height: 2.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${props => props.theme.images.select});
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position-x: 98%;
  background-position-y: 50%;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: var(--gray-500);
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:focus,
  &:hover {
    box-shadow: 0 0 0 2px var(--selected);
  }

  option {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: var(--gray-800);
    &:disabled {
      color: var(--gray-500);
      background-color: var(--gray-50);
    }
  }
`;
