import React from 'react';
import { Path, Rect, SVG, Stroke } from './style';

export const AnimalSVG = () => {
  return (
    <SVG viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <Stroke
            d="M17.3532 9.5576C10.3109 8.24949 1.2063 2.31268 1.2063 2.31268C1.2063 2.31268 1.3069 9.40667 6.94071 15.595"
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M25.1498 7.34394C25.1498 7.34394 16.9506 5.93521 16.347 12.8783C14.9587 15.6102 14.0382 17.0843 13.5301 18.8654C12.675 21.8489 12.841 25.9694 13.9426 28.8674C14.1589 29.4409 14.4205 30.0195 14.7374 30.5881C16.6991 34.1099 16.6991 39.9964 16.1961 42.1598C15.6931 44.3232 16.4979 49.0022 20.7736 49.0022C25.0492 49.0022 25.1498 49.0022 25.1498 49.0022"
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M13.8821 18.8605C13.8821 18.8605 21.2765 22.6389 20.3207 29.3807C19.365 36.1225 17.9113 35.8407 16.3771 41.0379"
         
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M14.7223 16.0078C14.7223 16.0078 9.45565 11.369 3.41943 19.2177C3.41943 19.2177 6.06531 24.5205 12.9919 23.5294"
          
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M32.9468 9.5576C39.989 8.24949 49.0937 2.31268 49.0937 2.31268C49.0937 2.31268 48.9931 9.40667 43.3593 15.595"
         
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M25.1499 7.34388C25.1499 7.34388 33.3491 5.93515 33.9527 12.8782C35.3411 15.6101 36.2616 17.0843 36.7696 18.8653C37.6248 21.8488 37.4588 25.9694 36.3571 28.8673C36.1408 29.4409 35.8793 30.0195 35.5624 30.588C33.6006 34.1098 33.6006 39.9963 34.1036 42.1597C34.6066 44.3232 33.8018 49.0022 29.5262 49.0022C25.2505 49.0022 25.1499 49.0022 25.1499 49.0022"
          
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M36.4176 18.8602C36.4176 18.8602 29.0233 22.6386 29.9791 29.3804C30.9348 36.1222 32.3885 35.8404 33.9227 41.0376"
          
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M35.5776 16.0076C35.5776 16.0076 40.8442 11.3688 46.8805 19.2175C46.8805 19.2175 44.2346 24.5204 37.308 23.5292"
           
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Stroke
            d="M24.0432 7.23834C24.0432 7.23834 24.9839 7.58334 26.674 7.23834"
       
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <Path
            d="M22.0999 44.1C22.9007 44.1 23.5499 43.4508 23.5499 42.65C23.5499 41.8492 22.9007 41.2 22.0999 41.2C21.2991 41.2 20.6499 41.8492 20.6499 42.65C20.6499 43.4508 21.2991 44.1 22.0999 44.1Z"
            
          />
          <Path
            d="M27.9 44.1C28.7008 44.1 29.35 43.4508 29.35 42.65C29.35 41.8492 28.7008 41.2 27.9 41.2C27.0991 41.2 26.45 41.8492 26.45 42.65C26.45 43.4508 27.0991 44.1 27.9 44.1Z"
          
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <Rect width="50" height="50" />
          </clipPath>
        </defs>
    </SVG>
  );
};
