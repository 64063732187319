import React, { ReactNode } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Box, Button, ButtonRipple, Flex } from './style';

type Ripple = {
  id: number;
  x: number;
  y: number;
};

type ButtonProps = {
  text: string;
  buttonFunction?: () => void;
  children?: ReactNode;
};

function RippleButton({ text, buttonFunction, children }: ButtonProps) {
  const CIRCLE_SIZE = 50;
  const ANIMATION_MS = 400;
  const [ripples, setRipples] = useState<Ripple[]>([] as Ripple[]);
  const boxRef = useRef<HTMLDivElement>(null);
  const handleClick = (event: MouseEvent) => {
    if (ripples.length > 0) return;
    const box = boxRef.current?.getBoundingClientRect();
    const id = ripples.length + 1;
    setRipples([
      ...ripples,
      {
        id: id,
        x: event.clientX - box!.left - CIRCLE_SIZE / 2,
        y: event.clientY - box!.top - CIRCLE_SIZE / 2,
      },
    ]);
    setTimeout(() => {
      setRipples(shift(ripples));
    }, ANIMATION_MS);
  };
  function shift(array: Ripple[]) {
    const newArray = [...array];
    newArray.shift();
    return newArray;
  }
  return (
    <Box ref={boxRef} onClick={(event: any) => handleClick(event)}>
      <Button onClick={buttonFunction}>
        <Flex>
          {children}
          <h3>{text}</h3>
        </Flex>
        {ripples.map(ripple => {
          return <ButtonRipple x={ripple.x} y={ripple.y} key={ripple.id} />;
        })}
      </Button>
    </Box>
  );
}

export default RippleButton;
