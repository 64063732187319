import React, { useRef, useState } from 'react';
import {
  NavBar,
  Divider,
  Button,
  UserIcon,
  Portrait,
  Arrow,
  GoBack,
  ReturnArrow
} from './style';
import { useAuth } from '../../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { UserCardMenu } from '../';

const Header = () => {
  function ConvertPathName(pathName: string) {
    const pathNameSplit = pathName.split(/[/]/g);
    const pathNameLength = pathNameSplit.length;
    const newPathNameArray = pathNameSplit[pathNameLength - 1].split(/[-]/g);
    const upperCaseLetterArray = newPathNameArray?.map(pathName => {
      const upperCaseLetter = pathName[0]?.toUpperCase();
      if(pathName === "cobertura") return `Inseminação`;
      if(pathName === "lactacao") return `Lactação`;
      if(pathName === "doenca") return `Doença`;
      return `${upperCaseLetter}${pathName.slice(1, pathName.length)}`;
    });
    let newPath = '';
    for (const path of upperCaseLetterArray) {
      newPath += ` ${path}`;
    }
    return newPath;
  }

  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { getTheme } = useTheme();
  const [profileIsActive, setProfileIsActive] = useState(false);
  const cardProfileRef = useRef<HTMLDivElement>(null);
  const signOutRef = useRef<HTMLButtonElement>(null);

  const closeCardProfile = (event: any) => {
    const contain = cardProfileRef.current?.contains(event.target);
    const containerSignOutFunction = signOutRef.current?.contains(event.target);
    if (containerSignOutFunction) {
      setProfileIsActive(false);
      document.body.removeEventListener('click', closeCardProfile);
    }
    if (!contain) {
      setProfileIsActive(false);
      document.body.removeEventListener('click', closeCardProfile);
    }
  };
  const openCardProfile = () => {
    setProfileIsActive(true);
    document.body.addEventListener('click', closeCardProfile);
  };
  const handleCardProfile = (event: any) => {
    if (profileIsActive) return closeCardProfile(event);
    if (!profileIsActive) return openCardProfile();
  };

  return (
    <>
    <ReturnArrow onClick={() => history.goBack()}/>
      <NavBar>
        <div>
          <GoBack onClick={() => history.goBack()} selectedTheme={getTheme()}>
            <Arrow />
          </GoBack>
        </div>
        <div>
          <h3>{ConvertPathName(location.pathname)}</h3>
        </div>
        <Divider>
          <Button onClick={handleCardProfile}>
            <Portrait>
              <UserIcon src={user?.avatar ? user?.avatar : '/icons/user.svg'} />
            </Portrait>
          </Button>
        </Divider>
      </NavBar>
      <UserCardMenu
        CardRef={cardProfileRef}
        profileIsActive={profileIsActive}
        signOutRef={signOutRef}
      />
    </>
  );
};
export default Header;
