import React, { ReactNode } from 'react';
import { useModal } from '../../hooks/useModal';
import { Box, DarkenBackground, CloseButton } from './style';

type ModalType = {
  children: ReactNode;
};

function Modal({ children }: ModalType) {
  const { closeModal, modalStatus } = useModal();
  return (
    <>
      {modalStatus && (
        <DarkenBackground>
          <Box>
            <CloseButton onClick={() => closeModal()}>
              <img src="/icons/cancel.svg" alt="close" />
            </CloseButton>
            {children}
          </Box>
        </DarkenBackground>
      )}
    </>
  );
}
export default Modal;
