import React, { createContext, useCallback, useState } from 'react';
import api from '../../../services/api/api';

interface User {
  id: string;
  nome: string;
  sobrenome: string;
  email: string;
  avatar: string;
  celular: string;
  ddd: string;
  tipo: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  senha: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@AgroSimulador:token');
    const user = localStorage.getItem('@AgroSimulador:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post('sessions', {
      email,
      senha,
    });

    const { token, user } = response.data;

    localStorage.setItem('@AgroSimulador:token', token);
    localStorage.setItem('@AgroSimulador:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@AgroSimulador:token');
    localStorage.removeItem('@AgroSimulador:user');
    setData({} as AuthState);
  }, []);
  const updateUser = useCallback(async () => {
    const response = await api.get('/profile');
    const { object } = response.data;
    localStorage.setItem('@AgroSimulador:user', JSON.stringify(object));
  }, []);
  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
