import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  height: fit-content;
  border: 1px solid var(--table-line);
  margin-top: 1rem;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: #e7ede9;
    border-left: 1px solid var(--outline);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--outline);
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #9fc7b3;
  }

  @media (max-width: 800px) {
    & {
      width: 96%;
    }
  }
`;
